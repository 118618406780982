import React, { useRef, useEffect, useState } from 'react';
import { BtnBack, Button, EButtonClass, EButtonType, EInputType, SuccessNotification } from '../../components';
import { StatusCode, URLS } from '../../../utils';
import { EApiUrls } from '../../../api';
import { useNavigate } from 'react-router-dom';
import jsQR from 'jsqr';
import { mutate } from 'swr';

const FAIL_FETCH = 'Произошла ошибка при активации QR-кода. Пожалуйста, попробуйте еще раз';

const isIOS = () => {
  if (typeof navigator === 'undefined') return false;
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};

const ScannerPage: React.FC = () => {
  const [isManualInput, setIsManualInput] = useState(false);
  const [value, setValue] = useState<string>('');
  const [isOpenSuccessNotification, setIsOpenSuccessNotification] = useState<boolean>(false);
  const [isOpenNegativeNotification, setIsOpenNegativeNotification] = useState<boolean>(false);
  const [isScanned, setIsScanned] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isPageVisible, setIsPageVisible] = useState<boolean>(true);
  const [cameraError, setCameraError] = useState<string | null>(null);
  const [isCameraStarted, setIsCameraStarted] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState<boolean>(false);
  const navigate = useNavigate();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    setIsIOSDevice(isIOS());
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsPageVisible(true);
      } else {
        setIsPageVisible(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const startCamera = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setCameraError('Ваш браузер не поддерживает доступ к камере.');
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = async () => {
          try {
            await videoRef.current?.play();
          } catch (err) {
            console.error('Ошибка при воспроизведении видео:', err);
          }
        };
      }
      setCameraError(null);
    } catch (err) {
      console.error('Ошибка доступа к камере:', err);
      if (err instanceof DOMException) {
        switch (err.name) {
          case 'NotAllowedError':
            setCameraError('Доступ к камере отклонён. Пожалуйста, разрешите доступ к камере в настройках браузера.');
            break;
          case 'NotFoundError':
            setCameraError('Камера не найдена. Убедитесь, что камера подключена и работает.');
            break;
          default:
            setCameraError('Не удалось получить доступ к камере. Пожалуйста, попробуйте снова.');
        }
      } else {
        setCameraError('Произошла неизвестная ошибка при доступе к камере.');
      }
    }
  };

  useEffect(() => {
    if (!isIOSDevice) {
      setTimeout(() => {
        startCamera();
        setIsCameraStarted(true);
      }, 500);
    }
  }, [isIOSDevice]);

  useEffect(() => {
    const scan = () => {
      if (videoRef.current && canvasRef.current && !isScanned && !cameraError) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (context) {
          if (video.videoWidth === 0 || video.videoHeight === 0) {
            animationFrameRef.current = requestAnimationFrame(scan);
            return;
          }

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          try {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
              handleScan({ text: code.data });
              return;
            }
          } catch (err) {
            console.error('Ошибка при обработке изображения канваса:', err);
          }

          animationFrameRef.current = requestAnimationFrame(scan);
        }
      }
    };

    if (!isIOSDevice && isCameraStarted && !isManualInput && !cameraError) {
      animationFrameRef.current = requestAnimationFrame(scan);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isScanned, isManualInput, cameraError, isCameraStarted, isIOSDevice]);

  useEffect(() => {
    if (isIOSDevice && isCameraStarted && !isManualInput && !cameraError) {
      const scan = () => {
        if (videoRef.current && canvasRef.current && !isScanned && !cameraError) {
          const video = videoRef.current;
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');

          if (context) {
            if (video.videoWidth === 0 || video.videoHeight === 0) {
              animationFrameRef.current = requestAnimationFrame(scan);
              return;
            }

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            try {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
              const code = jsQR(imageData.data, imageData.width, imageData.height);

              if (code) {
                handleScan({ text: code.data });
                return;
              }
            } catch (err) {
              console.error('Ошибка при обработке изображения канваса:', err);
            }

            animationFrameRef.current = requestAnimationFrame(scan);
          }
        }
      };

      animationFrameRef.current = requestAnimationFrame(scan);

      return () => {
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }
  }, [isIOSDevice, isCameraStarted, isScanned, isManualInput, cameraError]);

  useEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, []);

  const handleScan = (result: any) => {
    if (result?.text && !isScanned) {
      setValue(result.text);
      setIsManualInput(true);
      setIsScanned(true);
    }
  };

  const handleManualInput = () => {
    setIsManualInput(true);
  };

  const handleBackToScan = () => {
    setIsManualInput(false);
    setValue('');
    setIsScanned(false);
    setErrorMessage(null);
    setCameraError(null);
  };

  const handleClear = () => {
    handleBackToScan();
  };

  const handleActivate = async () => {
    setErrorMessage(null);
    try {
      const payload = { code: value };

      const response = await fetch(EApiUrls.ACTIVATE_PROMOCODE, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const { data } = await response.json();

      if (response.ok) {
        setIsOpenSuccessNotification(true);
        setValue('');
        mutate(EApiUrls.POINTS);
      } else if (response.status === StatusCode.NO_AUTH) {
        navigate(URLS.LOGIN);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
        setIsOpenNegativeNotification(true);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
      setIsOpenNegativeNotification(true);
    }
  };

  const handleStartScanningIOS = () => {
    startCamera();
    setIsCameraStarted(true);
  };

  return (
    <>
      {isManualInput ? (
        <div className="h-full pt-[64px] flex flex-col justify-start items-center bg-white w-full">
          <BtnBack />
          <div className="w-full bg-white p-[16px] rounded-lg flex-1 flex-col justify-center">
            <h2 className="font-medium mb-[32px] text-[26px] text-header">Активация QR-кода</h2>
            <div className="text-main text-[14px] leading-[18.9px] mb-[32px]">
              Введите код с купона из упаковки продукции и нажмите кнопку активировать
            </div>
            <p className="text-[14px] leading-[18.9px] mb-[4px] font-normal">QR-код</p>
            <div className="relative w-full mb-4">
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                type={EInputType.TEXT}
                placeholder="Введите QR-код"
                className="focus:outline-none focus:ring-1 focus:ring-green w-full pt-[13px] pb-[14px] px-[12px] border rounded-[12px] leading-[20.8px]"
              />
              <button
                onClick={handleClear}
                type={EButtonType.BUTTON}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 rounded-full focus:outline-none"
              >
                &#8634;
              </button>
            </div>
            {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}
          </div>

          <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
            <Button
              text="Активировать"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              isDisabled={!value}
              handle={handleActivate}
            />
          </div>

          {isOpenSuccessNotification && (
            <SuccessNotification
              title={'QR-код успешно активирован, баллы зачислены на ваш счет'}
              setIsOpenNotification={setIsOpenSuccessNotification}
              button={<Button text="Хорошо" nameClass={EButtonClass.DEF} isLink={true} linkUrl={URLS.HOME} />}
              isSuccess={true}
            />
          )}

          {isOpenNegativeNotification && (
            <SuccessNotification
              title={errorMessage || FAIL_FETCH}
              setIsOpenNotification={setIsOpenNegativeNotification}
              button={
                <Button
                  text="Сканировать QR-код"
                  nameClass={EButtonClass.DEF}
                  isLink={false}
                  handle={() => {
                    setIsOpenNegativeNotification(false);
                  }}
                />
              }
              isSuccess={false}
            />
          )}
        </div>
      ) : (
        <div className="scanner-container relative w-full h-full">
          {cameraError ? (
            <div className="flex flex-col items-center justify-center h-full p-4">
              <p className="text-red-500 mb-4 text-center">{cameraError}</p>
              <Button
                text="Попробовать снова"
                nameClass={EButtonClass.DEF}
                isLink={false}
                typeBtn={EButtonType.BUTTON}
                handle={handleBackToScan}
              />
            </div>
          ) : isIOSDevice ? (
            <div className="scanner-ios-container flex flex-col items-center justify-center h-full p-4">
              <BtnBack />
              {!isCameraStarted ? (
                <div className="w-full bg-white p-[16px] rounded-lg flex-1 flex-col justify-center">
                  <h2 className="font-medium mb-[32px] text-[26px] text-header">Активация QR-кода</h2>
                  <div className="text-main text-[14px] leading-[18.9px] mb-[32px]">
                    Нажмите кнопку ниже, чтобы начать сканирование QR-кода
                  </div>
                  {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}
                </div>
              ) : null}
              {!isCameraStarted ? (
                <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
                  <Button
                    text="Начать сканирование"
                    nameClass={EButtonClass.DEF}
                    isLink={false}
                    typeBtn={EButtonType.BUTTON}
                    handle={handleStartScanningIOS}
                  />
                </div>
              ) : (
                <>
                  <div className="relative w-full h-full flex justify-center">
                    <video ref={videoRef} className="qr-reader w-full h-full object-cover" playsInline />
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <div className="absolute top-1/3 left-1/2 w-52 h-52 transform -translate-x-1/2 -translate-y-1/2">
                      <div className="absolute w-12 h-12 border-t-4 border-l-4 border-white rounded-tl-lg top-0 left-0"></div>
                      <div className="absolute w-12 h-12 border-t-4 border-r-4 border-white rounded-tr-lg top-0 right-0"></div>
                      <div className="absolute w-12 h-12 border-b-4 border-l-4 border-white rounded-bl-lg bottom-0 left-0"></div>
                      <div className="absolute w-12 h-12 border-b-4 border-r-4 border-white rounded-br-lg bottom-0 right-0"></div>
                    </div>
                    <div className="absolute top-[calc(50%+50px)] w-[90%] mt-2 text-white text-lg text-center">
                      Направьте камеру на QR-код
                      <br />
                      для сканирования
                    </div>
                    <div className="absolute top-[calc(50%+120px)] mt-2">
                      <Button
                        text="Ввести код"
                        nameClass={EButtonClass.TRE}
                        isLink={false}
                        typeBtn={EButtonType.BUTTON}
                        handle={handleManualInput}
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
                    <Button
                      text="Остановить сканирование"
                      nameClass={EButtonClass.DEF}
                      isLink={false}
                      typeBtn={EButtonType.BUTTON}
                      handle={handleBackToScan}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="relative w-full h-full flex justify-center">
              <video ref={videoRef} className="qr-reader w-full h-full object-cover" playsInline />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
              <div className="absolute top-1/3 left-1/2 w-52 h-52 transform -translate-x-1/2 -translate-y-1/2">
                <div className="absolute w-12 h-12 border-t-4 border-l-4 border-white rounded-tl-lg top-0 left-0"></div>
                <div className="absolute w-12 h-12 border-t-4 border-r-4 border-white rounded-tr-lg top-0 right-0"></div>
                <div className="absolute w-12 h-12 border-b-4 border-l-4 border-white rounded-bl-lg bottom-0 left-0"></div>
                <div className="absolute w-12 h-12 border-b-4 border-r-4 border-white rounded-br-lg bottom-0 right-0"></div>
              </div>
              <div className="absolute top-[calc(50%+50px)] w-[90%] mt-2 text-white text-lg text-center">
                Направьте камеру на QR-код
                <br />
                для сканирования
              </div>
              <div className="absolute top-[calc(50%+120px)] mt-2">
                <Button
                  text="Ввести код"
                  nameClass={EButtonClass.TRE}
                  isLink={false}
                  typeBtn={EButtonType.BUTTON}
                  handle={handleManualInput}
                />
              </div>
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
                <Button
                  text="Начать сканирование"
                  nameClass={EButtonClass.DEF}
                  isLink={false}
                  typeBtn={EButtonType.BUTTON}
                  handle={startCamera}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScannerPage;
